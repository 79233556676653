import { createRoot } from 'react-dom/client'
import { StrictMode } from 'react'
import { initializeSentry } from 'utils/initializeSentry'
import { RouterProvider } from 'react-router-dom'
import { router } from 'router'
import { SWRConfig } from 'swr'
import { Bifrost, Modal, enUS } from '@intility/bifrost-react'

import Authentication from 'auth/Authentication'
import { authorizedFetch } from 'auth'
import { config } from 'config'

// https://bifrost.intility.com/#/Welcome%20to%20Bifrost/Get%20started/Installation
import '@intility/bifrost-react/dist/bifrost-app.css'

const rootElement = document.getElementById('root')!
const root = createRoot(rootElement)
Modal.setAppElement(rootElement)

const swrConfigValue = {
  fetcher: (path: string) =>
    authorizedFetch(`${config.server_host}/${path}`).then(response =>
      response.json()
    )
}

root.render(
  <StrictMode>
    <Authentication>
      <SWRConfig value={swrConfigValue}>
        <Bifrost locale={enUS}>
          <RouterProvider router={router} />
        </Bifrost>
      </SWRConfig>
    </Authentication>
  </StrictMode>
)

initializeSentry()
