import { Configuration } from '@azure/msal-browser'

/**
 * MSAL config for the PublicClientApplication
 * @see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
const config: Configuration = {
  auth: {
    clientId: 'ec84c6f5-5ce3-4de9-be6a-f95bf04aac99',
    authority:
      'https://login.microsoftonline.com/9b5ff18e-53c0-45a2-8bc2-9c0c8f60b2c6',
    redirectUri: window.location.origin
  }
}

export { config }
