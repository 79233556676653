import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { faPlus, faSearch } from '@fortawesome/pro-regular-svg-icons'
import {
  FieldGroup,
  Input,
  Select,
  Button,
  Drawer,
  Pagination
} from '@intility/bifrost-react'

import type { FilterType, SearchFilterType, ToolType } from 'types/types'
import { useDebouncedState } from 'hooks/useDebounce'
import ErrorPage from 'components/ErrorPage'
import ToolsTable from './Tables/ToolsTable'
import SkeletonTable from './Tables/SkeletonTable'
import Starred from './Cards/Starred'
import useTools from 'api/useTools'
import ToolDrawerContent from './Drawer/ToolDrawerContent'
import { config } from 'config'
import './home.css'

type FilterOptionsType = {
  label: string
  value: FilterType
}

const searchFilters: { label: string; value: SearchFilterType }[] = [
  { label: 'Service', value: 'name' },
  { label: 'URL', value: 'url' },
  { label: 'Description', value: 'description' },
  { label: 'Departments', value: 'departments' },
  { label: 'Owners', value: 'owners' }
]

const filterOptions: FilterOptionsType[] = [
  { value: 'myServices', label: 'My services' },
  { value: 'released', label: 'Released' },
  { value: 'starred', label: 'Starred' },
  { value: 'unreleased', label: 'Unreleased' }
]

const Home = () => {
  const [query, setQuery] = useSearchParams()

  const [selectedTool, setSelectedTool] = useState<ToolType | undefined>()
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)

  // Search type
  const [searchValueFilter, setSearchValueFilter] = useState<SearchFilterType>(
    (query.get('type') as SearchFilterType) ?? 'name'
  )

  // Search value
  const [searchValue, setSearchValue] = useState(query.get('search') ?? '')
  const debouncedSearchValue = useDebouncedState(searchValue)

  // Selected filters
  const [selectedFilters, setSelectedFilters] = useState<FilterType[]>(
    (query.get('filters')?.split(',') as FilterType[]) ?? undefined
  )

  const pageSize = config.pageSize
  const [pageNumber, setPageNumber] = useState<number>(1)

  // Reset page number when filters change
  useEffect(() => {
    setPageNumber(1)
  }, [debouncedSearchValue, selectedFilters])

  // Tools data
  const {
    data: toolsData,
    count,
    isLoading,
    error
  } = useTools({
    searchValueFilter,
    searchValue: debouncedSearchValue,
    selectedFilters,
    skip: (pageNumber - 1) * pageSize
  })

  const totalPages = count ? Math.ceil(count / pageSize) : 0

  // Update query filters
  useEffect(() => {
    setQuery({
      ...(searchValueFilter &&
        searchValue &&
        searchValue.length > 0 && {
          type: searchValueFilter.toLowerCase()
        }),
      ...(searchValue && searchValue.length > 0 && { search: searchValue }),
      ...(selectedFilters &&
        selectedFilters.length > 0 && { filters: selectedFilters.toString() })
    })
  }, [searchValue, searchValueFilter, selectedFilters, setQuery])

  if (error && !toolsData) {
    return <ErrorPage error={error} />
  }

  return (
    <div className='home-page-container bf-content'>
      <Starred />

      <div className='home-page-inputs'>
        <FieldGroup>
          <Select
            label='filter type'
            hideLabel
            options={searchFilters}
            value={searchFilters.find(x => x.value === searchValueFilter)}
            onChange={item => item && setSearchValueFilter(item.value)}
          />
          <Input
            label='search'
            hideLabel
            icon={faSearch}
            rightIcon
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            clearable
            placeholder={`Search for ${searchValueFilter}`}
          />
        </FieldGroup>

        <Select
          label='filter'
          hideLabel
          placeholder='Select filters'
          isMulti
          options={filterOptions}
          value={filterOptions.filter(
            x => selectedFilters?.includes(x.value) ?? undefined
          )}
          onChange={items =>
            setSelectedFilters(items?.map(x => x.value) ?? undefined)
          }
        />

        <Button
          icon={faPlus}
          onClick={() => setDrawerOpen(true)}
          variant='filled'
        >
          Add service
        </Button>
      </div>

      {!toolsData && isLoading && <SkeletonTable />}

      {toolsData && (
        <ToolsTable
          tools={toolsData}
          selectedTool={selectedTool}
          setSelectedTool={setSelectedTool}
        />
      )}

      {totalPages > 1 && (
        <Pagination
          totalPages={totalPages}
          currentPage={pageNumber}
          onChange={page => setPageNumber(page)}
        />
      )}

      <Drawer
        header='Add new service'
        isOpen={drawerOpen}
        onRequestClose={() => setDrawerOpen(false)}
        overlay
        className='tool-drawer-container'
      >
        <ToolDrawerContent setOpen={setDrawerOpen} />
      </Drawer>
    </div>
  )
}

export default Home
