import { Nav } from '@intility/bifrost-react'
import Header from './Header/Header'

const Navigation = ({ children }: React.PropsWithChildren) => {
  return (
    <Nav
      appName='Tools'
      hideTheme
      logoOnClick={e => {
        e.preventDefault()
        window.location.href = '/'
      }}
      top={<Header />}
    >
      {children}
    </Nav>
  )
}

export default Navigation
