import {
  InteractionRequiredAuthError,
  InteractionType
} from '@azure/msal-browser'
import {
  AuthenticatedTemplate,
  MsalProvider,
  useMsalAuthentication
} from '@azure/msal-react'

import { PropsWithChildren, useEffect } from 'react'
import { instance } from './instance'

const loginRequest = {
  scopes: ['api://bc5b62fa-4705-4590-993e-04e1fecfe446/Tools.ReadWrite']
}

// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md#ssosilent-example
const Template = ({ children }: PropsWithChildren) => {
  const { login, error } = useMsalAuthentication(
    InteractionType.Silent,
    loginRequest
  )

  useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
      login(InteractionType.Redirect, loginRequest)
    }
  }, [error, login])

  return <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
}

const Authentication = ({ children }: PropsWithChildren) => {
  return (
    <MsalProvider instance={instance}>
      <Template>{children}</Template>
    </MsalProvider>
  )
}

export default Authentication
