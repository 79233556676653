import { Grid, Icon, Label, Modal, Tooltip } from '@intility/bifrost-react'
import { faCopy } from '@fortawesome/pro-regular-svg-icons'
import { EmployeeType, ToolType } from 'types/types'
import OwnerPicture from '../OwnerPicture'
import styles from './ToolModal.module.css'

type ToolModalProps = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  tool?: ToolType
}

const ToolModal = ({ open, setOpen, tool }: ToolModalProps) => {
  return (
    <Modal
      header={tool?.name ?? ''}
      isOpen={open}
      onRequestClose={() => setOpen(false)}
      className={styles.modal}
    >
      {tool && (
        <Grid className='bf-content' gap={24}>
          <div className={styles.description}>
            <Label>Description</Label>
            <span>{tool.description}</span>
          </div>

          <div>
            <Label>Main URL</Label>
            <a
              className='bf-link'
              href={tool.url}
              target='_blank'
              rel='noreferrer'
            >
              {tool.url}
            </a>
          </div>

          {tool.secondaryUrls && tool.secondaryUrls.length > 0 && (
            <div className={styles.secondaryUrls}>
              <Label>Other links</Label>
              <ul>
                <Grid gap={4}>
                  {tool.secondaryUrls.map(x => (
                    <li key={x.url}>
                      <Tooltip
                        content={x.url}
                        state='neutral'
                        placement='right'
                        className='bf-break-word'
                      >
                        {x.url.includes('http') ? (
                          <a
                            className='bf-title-link'
                            href={x.url}
                            target='_blank'
                            rel='noreferrer'
                          >
                            {x.type}
                          </a>
                        ) : (
                          <span>{x.type}</span>
                        )}
                      </Tooltip>
                      <Icon
                        icon={faCopy}
                        marginLeft
                        fixedWidth
                        onClick={() => navigator.clipboard.writeText(x.url)}
                        className={styles.copyIcon}
                      />
                    </li>
                  ))}
                </Grid>
              </ul>
            </div>
          )}

          <div className={styles.departments}>
            <Label>Department{tool.departments.length > 1 ? 's' : ''}</Label>
            <ul>
              {tool.departments.map(x => (
                <li key={x}>{x}</li>
              ))}
            </ul>
          </div>

          <Grid small={3} gap={24}>
            <OwnerSection label='Product owners' owners={tool.productOwners} />

            {tool.technicalOwners && tool.technicalOwners.length > 0 && (
              <OwnerSection
                label='Technical owners'
                owners={tool.technicalOwners}
              />
            )}

            {tool.designOwners && tool.designOwners.length > 0 && (
              <OwnerSection label='Design owners' owners={tool.designOwners} />
            )}
          </Grid>
        </Grid>
      )}
    </Modal>
  )
}

const OwnerSection = ({
  label,
  owners
}: {
  label: string
  owners: EmployeeType[]
}) => {
  return (
    <div>
      <Label className={styles.ownerLabel}> {label} </Label>
      <div className={styles.ownersContainer}>
        {owners.map(x => (
          <Tooltip key={x.fullName} content={x.fullName} state='neutral'>
            <div>
              <OwnerPicture employee={x} size={32} />
            </div>
          </Tooltip>
        ))}
      </div>
    </div>
  )
}

export default ToolModal
