import { Button, Icon } from '@intility/bifrost-react'
import { faStar } from '@fortawesome/pro-solid-svg-icons'
import { ToolType } from 'types/types'
import './Card.css'

type CardProps = {
  tool: ToolType
  setSelectedCard: React.Dispatch<React.SetStateAction<ToolType | undefined>>
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
}

const Card = ({ tool, setSelectedCard, setOpenModal }: CardProps) => {
  const handleViewDetails = () => {
    setSelectedCard(tool)
    setOpenModal(true)
  }

  return (
    <div className='starred-card'>
      <div className='card-header'>
        <Icon icon={faStar} />
        <span className='card-tool-name'> {tool.name} </span>
      </div>
      <p className='card-description'> {tool.description} </p>

      <div className='card-footer'>
        <Button onClick={() => handleViewDetails()}>View details</Button>
        <a href={tool.url} target='_blank' rel='noreferrer'>
          <Button>Visit page</Button>
        </a>
      </div>
    </div>
  )
}

export default Card
