import { Button, Modal } from '@intility/bifrost-react'
import useTools from 'api/useTools'
import { authorizedFetch } from 'auth'
import { ToolType } from 'types/types'

type DeleteToolModalProps = {
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  tool?: ToolType
}

const DeleteToolModal = ({ show, setShow, tool }: DeleteToolModalProps) => {
  const { mutate: mutateTools } = useTools({})
  const { mutate: mutateStarred } = useTools({ selectedFilters: ['starred'] })

  // Deleting Tool
  const deleteTool = async () => {
    if (!tool) return
    try {
      const res = await authorizedFetch(`tools/${tool.id}`, {
        method: 'DELETE'
      })
      if (!res.ok) {
        throw new Error('Bad response')
      }
      mutateTools()
      mutateStarred()
      setShow(false)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Modal
      isOpen={show}
      onRequestClose={() => setShow(false)}
      header='Delete the service?'
    >
      <p className='bf-p'>
        This action will remove <i>{tool?.name}</i> from Intility Tools. Are you
        sure?
      </p>

      <Modal.Footer>
        <Button
          type='button'
          onClick={() => setShow(false)}
          style={{ marginRight: '8px' }}
        >
          Cancel
        </Button>
        <Button
          type='button'
          state='alert'
          variant='outline'
          onClick={() => deleteTool()}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteToolModal
