import { useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  Icon,
  Input,
  Message,
  Select,
  TextArea
} from '@intility/bifrost-react'
import { faPlus, faTimes } from '@fortawesome/pro-regular-svg-icons'

import { useDebouncedState } from 'hooks/useDebounce'
import { EmployeeType, SecondaryUrl, ToolType } from 'types/types'
import Departments from './components/Departments'
import useEmployees from 'api/useEmployees'
import useTools from 'api/useTools'
import './ToolDrawer.css'
import useLocalStorageState from 'use-local-storage-state'
import { authorizedFetch } from 'auth'

type ToolDrawerProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  tool?: ToolType
  setShowDeleteModal?: React.Dispatch<React.SetStateAction<boolean>>
}

const ToolDrawerContent = ({
  setOpen,
  tool,
  setShowDeleteModal
}: ToolDrawerProps) => {
  // Local drawer tool state
  const [newTool, setNewTool] = useState<ToolType>(tool ?? ({} as ToolType))

  const [searchQuery, setSearchQuery] = useState('')
  const missingValue = 'Missing value'
  const noOptionsMsg = 'Search to get results...'
  const noResultsMsg = 'No results found'

  const [attemptedSave, setAttemptedSave] = useState(false)
  const [saving, setSaving] = useState(false)

  const { mutate: mutateTools } = useTools({})
  const { mutate: mutateStarred } = useTools({ selectedFilters: ['starred'] })

  // Fetching employees
  const { options: employeesOptions, isValidating } = useEmployees({
    query: searchQuery
  })

  const [searchQueryProduct, setSearchQueryProduct] = useState('')
  const [searchQueryTechnical, setSearchQueryTechnical] = useState('')
  const [searchQueryDesign, setSearchQueryDesign] = useState('')
  const debouncedProduct = useDebouncedState(searchQueryProduct)
  const debouncedTechnical = useDebouncedState(searchQueryTechnical)
  const debouncedDesign = useDebouncedState(searchQueryDesign)

  useEffect(() => {
    if (debouncedProduct.length > 0) setSearchQuery(debouncedProduct)
    else if (debouncedTechnical.length > 0) setSearchQuery(debouncedTechnical)
    else if (debouncedDesign.length > 0) setSearchQuery(debouncedDesign)
    else setSearchQuery('')
  }, [debouncedProduct, debouncedTechnical, debouncedDesign])

  // SORTING ARRAY
  const sortingArray = (a: any, b: any) => {
    const greater = 1
    const lower = b > a ? -1 : 0
    return a > b ? greater : lower
  }

  // REMOVING DUPLICATES FROM OWNERS
  const findDuplicates = (arr: EmployeeType[]) => {
    const singleOwners: EmployeeType[] = []
    arr.forEach(owner => {
      const exist = singleOwners.some(x => x.id === owner.id)
      if (!exist) singleOwners.push(owner)
    })
    return singleOwners
  }

  // Clicking save button
  const handleSaveService = () => {
    if (newTool.secondaryUrls && newTool.secondaryUrls.length > 0) {
      let missing = false
      newTool.secondaryUrls.forEach(x => {
        if (!x.type.length || !x.url.length) {
          missing = true
          return
        }
      })
      if (missing) {
        setAttemptedSave(true)
        return
      }
    }

    if (
      !newTool.name ||
      !newTool.url ||
      !newTool.description ||
      !newTool.departments ||
      newTool.departments?.length === 0 ||
      !newTool.productOwners ||
      newTool.productOwners?.length === 0
    ) {
      setAttemptedSave(true)
      return
    }
    setAttemptedSave(false)

    const allOwners = [
      ...newTool.productOwners,
      ...(newTool.technicalOwners ?? []),
      ...(newTool.designOwners ?? [])
    ]
    newTool.owners = findDuplicates(allOwners)
    newTool.secondaryUrls = newTool.secondaryUrls
      ? newTool.secondaryUrls.sort((a, b) => sortingArray(a.type, b.type))
      : []

    if (tool) {
      const updatedTool = {
        ...newTool,
        id: tool.id,
        lowerName: newTool.name?.toLowerCase()
      }
      updateService(updatedTool)
    } else {
      const updatedTool = { ...newTool, lowerName: newTool.name?.toLowerCase() }
      postService(updatedTool)
    }
  }

  const postService = async (newTool: ToolType) => {
    try {
      setSaving(true)
      const res = await authorizedFetch('tools', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newTool)
      })
      if (!res.ok) {
        throw new Error('Could not post service')
      }
      mutateTools()
      handleCancelDrawer()
    } catch (e) {
      console.log(e)
    } finally {
      setSaving(false)
    }
  }

  const updateService = async (updatedTool: ToolType) => {
    if (!tool?.id) {
      throw new Error('Missing id')
    }
    try {
      setSaving(true)
      const res = await authorizedFetch(`tools/${tool.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedTool)
      })
      if (!res.ok) {
        throw new Error('Could not update service')
      }
      mutateTools()
      mutateStarred()
      handleCancelDrawer()
    } catch (e) {
      console.log(e)
    } finally {
      setSaving(false)
    }
  }

  const handleCancelDrawer = () => {
    if (tool) {
      setNewTool(prev => ({
        ...prev,
        id: tool.id,
        lowerName: newTool.name?.toLowerCase()
      }))
    } else {
      setNewTool({} as ToolType)
    }
    setOpen(false)
    setAttemptedSave(false)
  }

  // Delete tool
  const handleDelete = () => {
    if (!tool || !setShowDeleteModal) return
    setOpen(false)
    setShowDeleteModal(true)
  }

  // Secondary URLs
  const handleAddFieldGroup = () => {
    setNewTool({
      ...newTool,
      secondaryUrls:
        newTool.secondaryUrls && newTool.secondaryUrls.length > 0
          ? [...newTool.secondaryUrls, { type: '', url: '' }]
          : [{ type: '', url: '' }]
    })
  }

  // Remove secondary url
  const handleRemoveFieldGroup = (i: number) => {
    if (!newTool.secondaryUrls) return
    const list = [...newTool.secondaryUrls]
    list.splice(i, 1)
    setNewTool({ ...newTool, secondaryUrls: list })
  }

  // Changing secondary url input fields
  const handleUrlInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number
  ) => {
    if (!newTool.secondaryUrls) return
    const { name, value } = e.target
    const list: SecondaryUrl[] = [...newTool.secondaryUrls]
    list[i][name as keyof SecondaryUrl] = value
    setNewTool({ ...newTool, secondaryUrls: list })
  }

  const [showMessage, setShowMessage] = useLocalStorageState(
    'showToolsDrawerMessage',
    { defaultValue: true }
  )

  return (
    <div className='tool-drawer'>
      <div className='tool-drawer-content'>
        <Message
          header='Knowledge is power'
          expandable
          isOpen={showMessage}
          onHeaderClick={() => setShowMessage(x => !x)}
          className='tool-drawer-message'
        >
          By clicking the + icon next to "Service url" you can add more links to
          your service. We recommend adding links to all kinds of documentation,
          for instance:
          <ul>
            <li>Swagger, backstage, and more...</li>
            <li>Dev and QA environment links</li>
            <li>Gitlab project url</li>
            <li>Uservoice project url</li>
          </ul>
        </Message>

        <div className='url-container'>
          <Input
            label='Service name'
            value={newTool.name ?? ''}
            onChange={e => setNewTool({ ...newTool, name: e.target.value })}
            {...(attemptedSave &&
              !newTool.name && {
                state: 'alert',
                feedback: missingValue
              })}
          />
          <Input
            label='Service URL'
            value={newTool.url ?? ''}
            onChange={e => setNewTool({ ...newTool, url: e.target.value })}
            {...(attemptedSave &&
              !newTool.url && {
                state: 'alert',
                feedback: missingValue
              })}
          />
          <Icon
            className='add-url-icon'
            onClick={() => handleAddFieldGroup()}
            icon={faPlus}
          />
        </div>

        {newTool.secondaryUrls &&
          newTool.secondaryUrls.map((x, i) => {
            return (
              <div key={i} className='url-container'>
                <Input
                  name='type'
                  label='URL type'
                  value={x.type ?? ''}
                  onChange={e => handleUrlInputChange(e, i)}
                />
                <Input
                  name='url'
                  label='URL'
                  value={x.url ?? ''}
                  onChange={e => handleUrlInputChange(e, i)}
                />
                <Icon
                  className='delete-url-icon'
                  onClick={() => handleRemoveFieldGroup(i)}
                  icon={faTimes}
                />
              </div>
            )
          })}
        <Checkbox
          label='Not yet released'
          checked={newTool.inDevelopment ? true : false}
          onChange={() =>
            setNewTool(prev => ({
              ...prev,
              inDevelopment: !newTool.inDevelopment
            }))
          }
        />

        <Departments
          newTool={newTool}
          setNewTool={setNewTool}
          attemptedSave={attemptedSave}
        />

        <Select
          label='Product Owner(s)'
          noOptionsMessage={() =>
            searchQueryProduct.length < 2 ? noOptionsMsg : noResultsMsg
          }
          isMulti
          options={employeesOptions}
          isLoading={isValidating && searchQueryProduct.length > 0}
          inputValue={searchQueryProduct}
          onInputChange={value => setSearchQueryProduct(value)}
          value={
            newTool?.productOwners?.map(owner => {
              return { label: owner.fullName, value: owner }
            }) ?? null
          }
          onChange={items =>
            setNewTool(prev => ({
              ...prev,
              productOwners: items.map(x => x.value)
            }))
          }
          {...(attemptedSave &&
            ((newTool.productOwners && newTool.productOwners.length === 0) ||
              !newTool.productOwners) && {
              state: 'alert',
              feedback: missingValue
            })}
        />

        <Select
          label='Tehcnical Owner(s)'
          noOptionsMessage={() =>
            searchQueryTechnical.length < 2 ? noOptionsMsg : noResultsMsg
          }
          isMulti
          options={employeesOptions}
          isLoading={isValidating && searchQueryTechnical.length > 0}
          inputValue={searchQueryTechnical}
          onInputChange={e => setSearchQueryTechnical(e)}
          value={
            newTool?.technicalOwners?.map(owner => {
              return { label: owner.fullName, value: owner }
            }) ?? null
          }
          onChange={items =>
            setNewTool(prev => ({
              ...prev,
              technicalOwners: items.map(x => x.value)
            }))
          }
          optional
        />

        <Select
          label='Design Owner(s)'
          noOptionsMessage={() =>
            searchQueryDesign.length < 2 ? noOptionsMsg : noResultsMsg
          }
          isMulti
          options={employeesOptions}
          isLoading={isValidating && searchQueryDesign.length > 0}
          inputValue={searchQueryDesign}
          onInputChange={e => setSearchQueryDesign(e)}
          value={
            newTool?.designOwners?.map(owner => {
              return { label: owner.fullName, value: owner }
            }) ?? null
          }
          onChange={items =>
            setNewTool(prev => ({
              ...prev,
              designOwners: items.map(x => x.value)
            }))
          }
          optional
        />

        <TextArea
          label='Description'
          value={newTool.description ?? ''}
          maxLength={150}
          description={`${
            newTool.description?.length ?? 0
          } / 150 characters used`}
          onChange={e =>
            e.target.value.length < 200 &&
            setNewTool(prev => ({ ...prev, description: e.target.value }))
          }
          {...(attemptedSave &&
            !newTool.description && {
              state: 'alert',
              feedback: missingValue
            })}
        />
      </div>

      <div className='tool-drawer-footer'>
        {tool && (
          <>
            <Button
              state='alert'
              variant='outline'
              onClick={() => handleDelete()}
            >
              Delete{' '}
            </Button>
            <div>
              <Button
                className='tool-drawer-cancel-btn'
                onClick={() => handleCancelDrawer()}
              >
                Cancel{' '}
              </Button>
              {saving ? (
                <Button onClick={() => {}} state='inactive' variant='filled'>
                  Saving ...
                </Button>
              ) : (
                <Button onClick={() => handleSaveService()} variant='filled'>
                  Save
                </Button>
              )}
            </div>
          </>
        )}
        {!tool && (
          <>
            <Button
              className='tool-drawer-cancel-btn'
              onClick={() => handleCancelDrawer()}
            >
              Cancel{' '}
            </Button>
            {saving ? (
              <Button onClick={() => {}} state='inactive' variant='filled'>
                Saving ...
              </Button>
            ) : (
              <Button onClick={() => handleSaveService()} variant='filled'>
                Save
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default ToolDrawerContent
