import { init, reactRouterV6Instrumentation } from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { useEffect } from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'

/**
 * Initializes sentry if the application is built for production.
 * @see https://docs.sentry.io/platforms/javascript/guides/react
 * @see https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
 */
function initializeSentry() {
  if (process.env.NODE_ENV !== 'development') {
    init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      release: process.env.REACT_APP_SENTRY_RELEASE,
      environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: reactRouterV6Instrumentation(
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          )
        })
      ],

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 0.5
    })
  }
}

export { initializeSentry }
