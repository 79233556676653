import React, { useEffect, useState } from 'react'

let debounce = <T extends any[]>(
  callback: (...args: T) => void,
  delay = 300
) => {
  let timeout: NodeJS.Timeout

  return (...args: T) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => callback(...args), delay)
  }
}

let useDebounce = <T>(
  input: T,
  setValue: React.Dispatch<React.SetStateAction<T>>,
  delay = 300
) => {
  useEffect(() => {
    let timeout = setTimeout(() => setValue(input), delay)
    return () => clearTimeout(timeout)
  }, [input, setValue, delay])
}

let useDebouncedState = <T>(input: T, delay = 300) => {
  let [value, setValue] = useState(input)
  useDebounce(input, setValue, delay)
  return value
}

export { debounce, useDebouncedState }
export default useDebounce
