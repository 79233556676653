import useSWRImmutable from 'swr/immutable'
import { EmployeeType } from 'types/types'

const useEmployeeId = (id: string) => {
  const { data, error, isValidating, mutate } = useSWRImmutable<EmployeeType>(
    id ? `employees/${id}` : null
  )

  return { data, error, isValidating, mutate }
}

export default useEmployeeId
