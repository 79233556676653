import { Select } from '@intility/bifrost-react'
import departments from '../../../../data/departments.json'
import { ToolType } from 'types/types'

type DepartmentsProps = {
  newTool: ToolType
  setNewTool: React.Dispatch<React.SetStateAction<ToolType>>
  attemptedSave: boolean
}

const Departments = ({
  newTool,
  setNewTool,
  attemptedSave
}: DepartmentsProps) => {
  const handleSelect = (items: any) => {
    const selectedDepartments = items.map((x: any) => x.value)
    setNewTool(prev => ({ ...prev, departments: selectedDepartments }))
  }

  return (
    <Select
      label='Departments'
      options={departments}
      isMulti
      value={departments.filter(x => newTool.departments?.includes(x.label))}
      onChange={items => handleSelect(items)}
      {...(attemptedSave &&
        ((newTool.departments && newTool.departments.length === 0) ||
          !newTool.departments) && {
          state: 'alert',
          feedback: 'Missing value'
        })}
    />
  )
}

export default Departments
