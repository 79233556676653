import { useEffect, useState } from 'react'
import { Icon, Skeleton } from '@intility/bifrost-react'
import { faUser } from '@fortawesome/pro-solid-svg-icons'
import { EmployeeType } from 'types/types'
import useEmployeeId from 'api/useEmployeeId'
import styles from './OwnerPicture.module.css'

const OwnerPicture = ({
  employee,
  size = 24
}: {
  employee: EmployeeType
  size?: number
}) => {
  const { data, isValidating } = useEmployeeId(employee.id)
  const uri = data?.pictureUri

  const [imgExists, setImgExists] = useState(false)

  useEffect(() => {
    if (!uri) return
    let image = new Image()
    image.src = uri

    image.onload = () => {
      setImgExists(true)
    }
  }, [uri])

  if (!data && isValidating) {
    return (
      <Skeleton.Rect
        className={styles.ownerImage}
        style={{ width: size + 'px', height: size + 'px' }}
      />
    )
  }

  if (!imgExists) {
    return (
      <div
        className={styles.ownerImage}
        style={{ width: size + 'px', height: size + 'px' }}
      >
        <Icon
          icon={faUser}
          style={{ width: size - 10 + 'px', height: size - 10 + 'px' }}
        />
      </div>
    )
  }

  return (
    <img
      src={uri}
      alt={''}
      className={styles.ownerImage}
      style={{ width: size + 'px', height: size + 'px' }}
    />
  )
}

export default OwnerPicture
